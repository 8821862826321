import React from "react";
import Navbar from "./Components/Nav";
import "./App.css";
import Foot from "./Components/Foot";

export default function App() {
    return (
        <>
            <Navbar />
            <Foot />
        </>
    );
}
